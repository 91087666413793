<template>
  <div>
    <NavBar></NavBar>
    <div class="container mt-3">
      <b-button :to="{name:routeNames.CART}" ><i class="bi bi-arrow-left"></i> Back to cart</b-button>
      <h3 class="text-center">Sign-in:</h3>
      <p class="text-center">You need to sign-in to access videos of your tutorial</p>
      <div id="firebaseui-auth-container"></div>
      <p class="text-center" style="font-size: 12px">I will never share your personal information with others</p>
      <p class="text-center" style="font-size: 12px">For security reasons: I do not store your credentials I use Google Firebase for login/signup</p>

    </div>

  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import * as firebaseui from "firebaseui"
require("firebaseui/dist/firebaseui.css")
import AuthHelper from "../authHelper";
import Consts from "@/consts";

export default {
  name: "FunnelLogin",
  components: {NavBar},
  data(){
    return {
      routeNames: Consts.ROUTE_NAMES,
    }
  },
  mounted(){
    const uiConfig = {
      signInFlow: "popup",
      signInSuccessUrl : "/checkout/pay",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          let user = authResult.user;
          let credential = authResult.credential;
          let isNewUser = authResult.additionalUserInfo.isNewUser;
          let providerId = authResult.additionalUserInfo.providerId;
          let operationType = authResult.operationType;
          // Do something with the returned AuthResult.
          // Return type determines whether we continue the redirect
          // automatically or whether we leave that to developer to handle.
          console.log(user,credential,isNewUser,providerId,operationType,redirectUrl)
          // here we merge if possible the anon user and firebase
          AuthHelper.mergeUser()
          return true
        },
      }
    };
    let firebaseUiInstance = firebaseui.auth.AuthUI.getInstance()
    if (firebaseUiInstance) {
      firebaseUiInstance.start("#firebaseui-auth-container",uiConfig)
    } else {
      const ui = new firebaseui.auth.AuthUI(firebase.auth())
      ui.start("#firebaseui-auth-container",uiConfig)
    }
  }
}
</script>

<style scoped>

</style>